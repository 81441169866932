import cn from 'classnames';
import Image from 'next/image';
import Link from 'next/link';
import styles from './article-card.module.css';

export interface ArticleCardProps {
	href: string;
	title: string;
	category?: string;
	image: {
		src: string;
		alt: string;
		height: number;
		width: number;
		ratio?: 'square' | 'rectangle';
		borderRadius?: string;
	};
	testId?: string;
	variant?: 'default' | 'compact';
}

const ArticleCard = (props: ArticleCardProps) => {
	const {
		category,
		href,
		image,
		testId = 'ArticleCardReactive',
		title,
		variant = 'default',
	} = props;

	function getImageSizes() {
		return [
			`(min-width: 640px) 576px`,
			`(min-width: 768px) 340px`,
			`(min-width: 1024px) 300px`,
			`(min-width: 1280px) 363px`,
			`(min-width: 1664px) 491px`,
			`calc(100vw - 4rem)`,
		].join(', ');
	}

	return (
		<Link href={href} className="hover:cursor-pointer" data-testid={testId}>
			<article
				className={cn(
					{
						'grid grid-cols-4 gap-5 sm:grid-cols-6 sm:gap-8 md:block':
							variant === 'compact',
					},
					styles['on-hover'],
				)}
			>
				<div
					className={cn(
						'relative',
						'overflow-clip rounded-2xl lg:rounded-5xl',
						{
							'aspect-square sm:col-span-2 sm:aspect-25/19 md:mb-4 lg:mb-6':
								variant === 'compact',
							'mb-4 aspect-25/19 lg:mb-8': variant === 'default',
						},
					)}
				>
					<Image
						src={image.src}
						alt={image.alt}
						className={cn('object-cover')}
						sizes={getImageSizes()}
						fill
					/>
					<div role="presentation" className={styles['shadow']} />
				</div>
				<div
					className={cn({
						'col-span-3 sm:col-span-4': variant === 'compact',
					})}
				>
					{category && (
						<div
							className={cn(
								'font-medium uppercase text-black/60',
								'pb-0.5 sm:pb-1 lg:pb-2',
								{
									'text-xs sm:text-sm lg:text-base': variant === 'compact',
									'pb-1 text-sm lg:text-base': variant === 'default',
								},
							)}
						>
							{category}
						</div>
					)}
					<h2
						className={cn({
							'text-lg font-semibold lg:text-xl': variant === 'default',
							'text-base font-semibold sm:text-lg lg:text-xl':
								variant === 'compact',
						})}
					>
						{title}
					</h2>
				</div>
			</article>
		</Link>
	);
};

export default ArticleCard;
